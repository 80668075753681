const locales = [
  {
    locale: 'en',
    messages: import('./en'),
  },
  {
    locale: 'th',
    messages: import('./th'),
  },
]

export default locales
