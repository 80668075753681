import React, { Component } from 'react'
import config from './config'

import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

// import App from 'base-shell/lib'
import App from './containers/App/App'

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

export default class Demo extends Component {
  render() {

    if (process.env.NODE_ENV === "production")  console.log = function no_console() {};

    return <App config={config} instance={msalInstance} />
    // return <App config={config} />
    
  }
}
